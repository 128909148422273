import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import DesignSystemSection from "../components/DesignSystem/DesignSystemSection/DesignSystemSection"
import Panel from "../components/DesignSystem/Panel/Panel"
import Panels from "../components/DesignSystem/Panels/Panels"
import TypeScale from "../components/DesignSystem/TypeScale/TypeScale"
import Title from "../components/Title/Title"
import * as DesignSystemStyles from "./design-system.module.css"
import Button from "../components/Button/Button"

export default function StyleGuide() {
    return (
        <Layout>
            <Helmet>
                <title>Visual Language | Paula Abbey Counselling & Psychotherapy</title>
            </Helmet>
            <Title>
                <h1>Visual Language</h1>
            </Title>
            <DesignSystemSection
            heading="Type scale"
            description="The type scale consists of 7 font sizes, each of which is a calculation using the clamp() function. Larger sizes scale up and down more than smaller sizes."
          >
            <TypeScale addedClass={DesignSystemStyles.xxl} />
            <TypeScale addedClass={DesignSystemStyles.xl} />
            <TypeScale addedClass={DesignSystemStyles.l} />
            <TypeScale addedClass={DesignSystemStyles.m} />
            <TypeScale addedClass={DesignSystemStyles.s} />
            <TypeScale addedClass={DesignSystemStyles.xs} />
            <TypeScale addedClass={DesignSystemStyles.xxs} />
          </DesignSystemSection>
          <DesignSystemSection
            heading="Colour palette"
            description="The colour palette consists of a set of shades of primary, secondary & neutral colours. Each is on a scale that starts at 100 and ends at 900 with intermediate shades evenly distributed between those values."
          >
            <h3>Primary</h3>
            <Panels>
                <Panel addedClass={DesignSystemStyles.primary50} />
                <Panel addedClass={DesignSystemStyles.primary100} />
                <Panel addedClass={DesignSystemStyles.primary200} />
                <Panel addedClass={DesignSystemStyles.primary300} />
                <Panel addedClass={DesignSystemStyles.primary400} />
                <Panel addedClass={DesignSystemStyles.primary500} />
                <Panel addedClass={DesignSystemStyles.primary600} />
                <Panel addedClass={DesignSystemStyles.primary700} />
                <Panel addedClass={DesignSystemStyles.primary800} />
                <Panel addedClass={DesignSystemStyles.primary900} />
            </Panels>
            <h3>Secondary</h3>
            <Panels>
                <Panel addedClass={DesignSystemStyles.secondary50} />
                <Panel addedClass={DesignSystemStyles.secondary100} />
                <Panel addedClass={DesignSystemStyles.secondary200} />
                <Panel addedClass={DesignSystemStyles.secondary300} />
                <Panel addedClass={DesignSystemStyles.secondary400} />
                <Panel addedClass={DesignSystemStyles.secondary500} />
                <Panel addedClass={DesignSystemStyles.secondary600} />
                <Panel addedClass={DesignSystemStyles.secondary700} />
                <Panel addedClass={DesignSystemStyles.secondary800} />
                <Panel addedClass={DesignSystemStyles.secondary900} />
            </Panels>              
            <h3>Neutral</h3>
            <Panels>
                <Panel addedClass={DesignSystemStyles.neutral50} />
                <Panel addedClass={DesignSystemStyles.neutral100} />
                <Panel addedClass={DesignSystemStyles.neutral200} />
                <Panel addedClass={DesignSystemStyles.neutral300} />
                <Panel addedClass={DesignSystemStyles.neutral400} />
                <Panel addedClass={DesignSystemStyles.neutral500} />
                <Panel addedClass={DesignSystemStyles.neutral600} />
                <Panel addedClass={DesignSystemStyles.neutral700} />
                <Panel addedClass={DesignSystemStyles.neutral800} />
                <Panel addedClass={DesignSystemStyles.neutral900} />
            </Panels>
          </DesignSystemSection>
          <DesignSystemSection
            heading="Shadows"
            description="There are 5 box shadows to represent different levels of elevation from the page."
          >
            <Panels style={{marginTop: 40, justifyContent: "space-between", backgroundColor: "var(--neutral-100)", padding: "5%"}}>
              <Panel addedClass={DesignSystemStyles.xSmallShadow} />
              <Panel addedClass={DesignSystemStyles.smallShadow} />
              <Panel addedClass={DesignSystemStyles.mediumShadow} />
              <Panel addedClass={DesignSystemStyles.largeShadow} />
              <Panel addedClass={DesignSystemStyles.xLargeShadow} />
            </Panels>
          </DesignSystemSection>
          <DesignSystemSection
            heading="Buttons"
            description="There are 3 core button styles: Primary is the default; Secondary is for buttons that are key CTAs; Neutral is for buttons that are to be de-emphasized."
          >
            <div className={DesignSystemStyles.buttonContainer}>
              <Button colour="primary" to="#">Primary button</Button>
              <Button colour="secondary" to="#">Secondary button</Button>
              <Button colour="neutral" to="#">Neutral button</Button>
            </div>
          </DesignSystemSection>
        </Layout>
    )
}